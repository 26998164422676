import "core-js/stable";
import "regenerator-runtime/runtime";

import Swiper, { EffectFade, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Collapse, Modal, Popover } from 'bootstrap';

import Plyr from 'plyr';

import axios from 'axios';
import imagesLoaded from 'imagesloaded';
import Packery from 'packery';

Swiper.use( [EffectFade, Navigation, Pagination] );

import Calendar from '@event-calendar/core';
import DayGrid from '@event-calendar/day-grid';

import "../scss/style.scss";

(function (doc, win) {
    const app = {
        initHeaderSearchForm() {
            const headerNav = doc.getElementById( "header-navbar-toggler" )
            new Collapse( headerNav, {
                toggle: false
            } )
            const headerSearchForm = doc.getElementById( "header-search-form" )
            new Collapse( headerSearchForm, {
                toggle: false
            } )
            const searchFormCollapse = Collapse.getInstance( headerSearchForm )
            const navCollapse = Collapse.getInstance( headerNav )

            headerSearchForm.addEventListener( 'show.bs.collapse', (e) => {
                navCollapse.hide()
            })

            doc.addEventListener( "click", (e) => {
                if ( ( e.target == headerSearchForm ) || ( e.target.parentNode == headerSearchForm )) {
                    return
                } else {
                    searchFormCollapse.hide()
                }
            })
        },
        initHoverDropdown() {
            const dropdownParents = doc.querySelectorAll( ".header .nav-item.dropdown" )
            if ( dropdownParents.length > 0 ) {
                dropdownParents.forEach(
                    dp => {
                        dp.onmouseenter = e => {
                            e.target.querySelector( ".dropdown-menu" ).classList.add( "show" )
                            const dropdownToggle = e.target.querySelector( ".dropdown-toggle" )
                            dropdownToggle.classList.add( "show" )
                            dropdownToggle.setAttribute( "aria-expanded", true )
                        }
                        dp.onmouseleave = e => {
                            e.target.querySelector( ".dropdown-menu" ).classList.remove( "show" )
                            const dropdownToggle = e.target.querySelector( ".dropdown-toggle" )
                            dropdownToggle.classList.remove( "show" )
                            dropdownToggle.setAttribute( "aria-expanded", false )
                        }
                    }
                )
            }
        },
        initGalleryModal() {
            const gallerySections = doc.querySelectorAll( ".section-gallery.has-modal, .section-short-films, .section-webisodes" )
            if ( gallerySections.length > 0 ) {
                
                gallerySections.forEach(
                    section => section.onclick = function (e) {
                        const YOUTUBE_API_KEY = "AIzaSyCQBo4gbvh_saH9Omnw7enIayFVNBj8HaM";
                        section.querySelectorAll( ".video-description" ).forEach(
                            vd => {
                                fetch((`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${vd.dataset.modalYoutubeVideoId}&fields=items/snippet/title,items/snippet/description&key=${YOUTUBE_API_KEY}`))
                                .then(response => response.json())
                                .then(data => {
                                    console.log( data.items[0].snippet )
                                    let description = data.items[0].snippet.description.replace(/(?:\r\n|\r|\n)/g, '<br>')
                                    description = description.replace( '<br><br>', '<br>' )
                                    vd.innerHTML = description
                                })
                            }
                        )

                        if ( e.target.classList.contains( "gallery-item" ) || e.target.classList.contains( "watch" ) ) {
                            const modalTarget = e.target.dataset.target

                            const slideIndex = Array.from(e.target.closest( ".gallery-col, .swiper-slide" ).parentNode.children).indexOf(e.target.closest( ".gallery-col, .swiper-slide" ))

                            const swiperElement = section.querySelector( ".modal-swiper" )
                            const swiper = new Swiper( swiperElement, {
                                initialSlide: slideIndex,
                                observer: true,
                                observeParents: true,
                                observeChildren: true,
                                spaceBetween: 0,
                                navigation: {
                                    nextEl: section.querySelector( ".modal .swiper-button-next" ),
                                    prevEl: section.querySelector( ".modal .swiper-button-prev" )
                                },
                                zoom: {
                                    maxRatio: 2,
                                    toggle: true,  // enable zoom-in by double tapping slide
                                },
                                preloadImages: false,
                                // Enable lazy loading
                                lazy: true,
                                lazy: {
                                    loadPrevNext: true,
                                    //loadOnTransitionStart: true,
                                },
                                effect: 'coverflow',
                                coverflowEffect: {
                                    rotate: 60,
                                    slideShadows: false,
                                },
                                loop: false,
                            })
                                
                            swiper.on('slideChange', e => {
                                section.querySelectorAll('.youtube-video').forEach(
                                    yv => {
                                        yv.contentWindow.postMessage(JSON.stringify(
                                            { event: 'command', func: 'pauseVideo' }), 'https://www.youtube.com')
                                    }
                                )
                            })

                            const modal = new Modal( modalTarget );
                            doc.querySelector( modalTarget ).addEventListener('show.bs.modal', e => {
                                
                            })
                            doc.querySelector( modalTarget ).addEventListener('hidden.bs.modal', e => {
                                
                                section.querySelectorAll('.youtube-video').forEach(
                                    yv => {
                                        yv.contentWindow.postMessage(JSON.stringify(
                                            { event: 'command', func: 'pauseVideo' }), 'https://www.youtube.com')
                                    }
                                )
                            })
                            modal.show()
                        }
                    }
                )
            }
        },
        initAudioPlayerModal() {
            const audibleSections = doc.querySelectorAll( ".section-audibles" )
            if ( audibleSections.length > 0 ) {
                audibleSections.forEach(
                    section => section.onclick = function (e) { 
                        if ( e.target.classList.contains( "gallery-item" ) || e.target.classList.contains( "play" ) ) {
                            const modalTarget = e.target.dataset.target
                            if ( modalTarget ) {
                                let player = null;
                                if ( e.target.dataset.modalType == 'audible' ) {
                                    const playerSelector = `${modalTarget} .player`
                                    const videoDescription = doc.querySelector( `${modalTarget} .video-description` )
                                    const playerOption = {
                                        //ratio: win.matchMedia('(min-width: 1024px)').matches ? '' : '5:9'
                                    }
                                    doc.querySelector( `${modalTarget} .player source` ).src = e.target.dataset.modalAudioFile

                                    player = new Plyr( 'audio', playerOption );
                                    videoDescription.innerHTML = e.target.dataset.modalDescription
                                }
                                const modal = new Modal( modalTarget );
                                doc.querySelector( modalTarget ).addEventListener('show.bs.modal', e => {
                                    
                                })
                                doc.querySelector( modalTarget ).addEventListener('hidden.bs.modal', e => {
                                    if ( player ) {
                                        player.destroy()
                                    }
                                })
                                modal.show()
                            }
                        }
                    }
                )
            }
        },
        initFooterNewsletterForm() {
            const form = document.getElementById( "footer-newsletter-form" )
            if ( form ) {
                form.onsubmit = function(e) {
                    e.preventDefault()
                    const email = e.target.elements.MERGE0.value
                    win.location.href = `https://us4.list-manage.com/subscribe?u=6a519986dbb848b89ab5536c5&id=e8d215dfe2&MERGE0=${email}`
                }
            }
        },
        initSwiperSliders() {
            const swipers = doc.querySelectorAll( ".mySwiper3" )
            if ( swipers.length > 0 ) {
                swipers.forEach(
                    swiper => new Swiper( swiper, {
                        slidesPerView: 1.5,
                        spaceBetween: 16,
                        grabCursor: true,
                        mousewheel: {
                            forceToAxis: true
                        },
                        
                        navigation: {
                            nextEl: swiper.closest( ".work-slider" ).querySelector( ".swiper-button-next" ),
                            prevEl: swiper.closest( ".work-slider" ).querySelector( ".swiper-button-prev" )
                        },
                        breakpoints: {
                            375: {
                                slidesPerView: 2,
                                spaceBetween: 16,
                            },
                            500: {
                                slidesPerView: 2.5,
                                spaceBetween: 16,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 16,
                            },
                            1025: {
                                slidesPerView: 4.5,
                                spaceBetween: 16,
                            },
                            1366: {
                                slidesPerView: 4,
                                spaceBetween: 16,
                            },
                            1920: {
                                slidesPerView: 4.5,
                                spaceBetween: 16,
                            }
                        },
                    })
                )
            }

            const memberSwipers = doc.querySelectorAll( ".member-swiper" )
            if ( memberSwipers.length > 0 ) {
                memberSwipers.forEach(
                    swiper => new Swiper( swiper, {
                        slidesPerView: 4.5,
                        spaceBetween: 16,
                        grabCursor: true,
                        mousewheel: {
                            forceToAxis: true
                        },
                        navigation: {
                            nextEl: swiper.closest( ".about-slider-wrp" ).querySelector( ".swiper-button-next" ),
                            prevEl: swiper.closest( ".about-slider-wrp" ).querySelector( ".swiper-button-prev" )
                        },
                        breakpoints: {
                            320: {
                                slidesPerView: 1.5,
                                spaceBetween: 16,
                            },
                            448: {
                                slidesPerView: 2.5,
                                spaceBetween: 16,
                            },
                            768: {
                                slidesPerView: 3.5,
                                spaceBetween: 16,
                            },
                            1025: {
                                slidesPerView: 5.5,
                                spaceBetween: 16,
                            },
                            1366: {
                                slidesPerView: 4,
                                spaceBetween: 16,
                            },
                            1920: {
                                slidesPerView: 5,
                                spaceBetween: 16,
                            }
                        },
                    })
                )
            }
            
        },
        initMobileMenuOverlay() {
            const menuButton = doc.getElementById( "menu-button" )
            const mobileMenuToggler = doc.getElementById( "mobile-menu-toggler" )
            const pageBody = doc.querySelector( "body" )
            menuButton.addEventListener( "click", e => {
                mobileMenuToggler.classList.toggle( "expand-menu-open" )
                pageBody.classList.toggle( "mobile-menu-open" )
                menuButton.classList.toggle( "mobile-collapsed" )
            } )
        },
        initDigitalScrapbook() {
            const digitalScrapbookRows = doc.querySelectorAll( ".digital-scrapbook" )
            if ( digitalScrapbookRows.length ) {
                digitalScrapbookRows.forEach(
                    ds => ds.onclick = e => { 
                        if ( e.target.classList.contains( "show-digital-scrapbook" ) ) {
                            e.target.classList.add( 'd-none' )
                            const loader = ds.querySelector( '.loader' )
                            const gallery = ds.querySelector( '.digital-scrapbook-gallery' )
                            const url = e.target.dataset.url
                            const id = e.target.dataset.id

                            loader.classList.remove( 'd-none' )
                            axios.get( url, {
                                headers: { "X-Requested-With": "XMLHttpRequest"},
                                params: {
                                    id: id
                                }
                            })
                            .then(response => {
                                gallery.innerHTML = response.data
                                imagesLoaded( ds, instance => {
                                    loader.classList.add( 'd-none' )
                                    new Packery( gallery, {
                                        itemSelector: '.grid-item',
                                        gutter: 15
                                    });
                                })
                                
                            })
                            .catch(error => {
                                console.log(error)
                            })
                        }
                    }
                )
            }
        },
        initReadMore() {
            const readMoreContainers = doc.querySelectorAll( ".read-more-container" )
            if ( readMoreContainers.length ) {
                readMoreContainers.forEach(
                    rmc => rmc.onclick = e => {
                        if ( e.target.classList.contains( "expand-btn" ) ) {
                            rmc.classList.add( "expanded" )
                            e.target.classList.add( "d-none" )
                        }
                    }
                )
            }
        },
        initDigitalProductModal() {
            const modalTriggers = doc.querySelectorAll( ".digital-product-modal-trigger" )
            if ( modalTriggers.length > 0 ) {
                modalTriggers.forEach(
                    modalTrigger => modalTrigger.addEventListener( "click", function (e) { 
                        console.log(e.currentTarget)
                        
                        const modalTarget = e.currentTarget.dataset.target
                        let player = null;
                        if ( e.currentTarget.dataset.modalType == 'audible' ) {
                            const videoDescription = doc.querySelector( `${modalTarget} .video-description` )
                            const playerOption = {
                                //ratio: win.matchMedia('(min-width: 1024px)').matches ? '' : '5:9'
                            }
                            doc.querySelector( `${modalTarget} .player source` ).src = e.target.dataset.modalAudioFile
                            player = new Plyr( 'audio', playerOption );
                            videoDescription.innerHTML = e.target.dataset.modalDescription
                        }
                        if ( e.currentTarget.dataset.modalType == 'video' ) {
                            const playerSelector = `${modalTarget} .player`
                            //const videoDescription = doc.querySelector( `${modalTarget} .video-description` )
                            const playerOption = {
                                ratio: win.matchMedia('(min-width: 1024px)').matches ? '' : '5:9'
                            }
                            player = new Plyr( playerSelector, playerOption );
                            //videoDescription.innerHTML = e.target.dataset.modalDescription
                        }

                        const modal = new Modal( modalTarget );
                        doc.querySelector( modalTarget ).addEventListener('show.bs.modal', e => {
                            
                        })
                        doc.querySelector( modalTarget ).addEventListener('hidden.bs.modal', e => {
                            if ( player ) {
                                player.destroy()
                            }
                        })
                        modal.show()
                        
                    })
                )
            }
        },
        initHeroSlider() {
            new Swiper(".hero-slider", {
                slidesPerView: 1,
                spaceBetween: 30,
                speed: 1000,
                slidesPerGroup: 1,
                loop: true,
                effect: "fade",
                loopFillGroupWithBlank: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                fadeEffect: {
                    crossFade: true
                },
            })
        },
        initEventCalendar() {
            const calendarContainer = doc.querySelector( '.events-calendar-block' )
            if ( calendarContainer ) {
                fetch('/events', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                })
                .then(response => {
                    if(!response.ok) throw new Error(response.status);
                    else return response.json();
                })
                .then(events => {

                    const ec = new Calendar({
                        target: calendarContainer,
                        props: {
                            plugins: [DayGrid],
                            options: {
                                eventStartEditable: false,
                                height: '600px',
                                view: 'dayGridMonth',
                                events: events,
                                views: {
                                    timeGridWeek: {pointer: true},
                                    resourceTimeGridWeek: {pointer: true}
                                },
                                dayMaxEvents: true,
                            }
                        }
                    })

                    new Popover(doc.body, {
                        selector: '.has-popover',
                        html: true,
                        trigger: 'focus'
                    })
                    
                })
                .catch((error) => {
                    console.log('error: ' + error);
                })
            }
            function createEvents() {
                // let days = [];
                // for (let i = 0; i < 7; ++i) {
                //     let day = new Date();
                //     let diff = i - day.getDay();
                //     day.setDate(day.getDate() + diff);
                //     days[i] = day.getFullYear() + "-" + _pad(day.getMonth()+1) + "-" + _pad(day.getDate());
                // }

                // const jsonresult = [
                //     {start: days[0] + " 10:00", end: days[0] + " 14:00", title: "The calendar can display background and regular events", color: "#FE6B64"},
                //     {start: days[1] + " 16:00", end: days[2] + " 08:00", title: "An event may span to another day", color: "#B29DD9"},
                //     {start: days[2] + " 09:00", end: days[2] + " 13:00", title: "Events can be assigned to resources and the calendar has the resources view built-in", color: "#779ECB"},
                //     {start: days[3] + " 15:00", end: days[3] + " 18:00", title: "Overlapping events are positioned properly", color: "#779ECB"},
                //     {start: days[5] + " 10:00", end: days[5] + " 16:00", title: "You have complete control over the <i><b>display</b></i> of events…", color: "#779ECB"},
                //     {start: days[5] + " 14:00", end: days[5] + " 19:00", title: "…and you can drag and drop the events!", color: "#FE6B64"},
                //     {start: days[1], end: days[3], title: "All-day events can be displayed at the top", color: "#B29DD9", allDay: true}
                // ];

                // console.log(jsonresult)
                // return jsonresult
                
            }
        
            function _pad(num) {
                let norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            }
        },
        init() {
            //this.initLazy()
            //this.initHoverDropdown()
            this.initHeaderSearchForm()
            this.initGalleryModal()
            this.initAudioPlayerModal()
            this.initFooterNewsletterForm()
            this.initSwiperSliders()
            this.initMobileMenuOverlay()
            this.initDigitalScrapbook()
            this.initReadMore()
            this.initDigitalProductModal()
            this.initHeroSlider()
            this.initEventCalendar()
        }
    }
    app.init()
})(document, window);



if (module.hot) {
    module.hot.accept();
}